<template>
	<div>
		<section class="hero-wrap hero-wrap-2 content-img" data-stellar-background-ratio="0.5">
			<div class="overlay"></div>
			<div class="container">
				<div class="row no-gutters slider-text align-items-end justify-content-start">
					<div class="col-md-9  pb-5">
						<h1 class="mb-3 bread">电力智能化</h1>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 ">
						<h2 class="mb-3">It is a long established fact a reader be distracted</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam
							doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente
							officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,
							iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.</p>
						<p>
							<img src="images/image_3.jpg" alt="" class="img-fluid">
						</p>
						<p>Molestiae cupiditate inventore animi, maxime sapiente optio, illo est nemo veritatis repellat sunt
							doloribus nesciunt! Minima laborum magni reiciendis qui voluptate quisquam voluptatem soluta illo eum
							ullam incidunt rem assumenda eveniet eaque sequi deleniti tenetur dolore amet fugit perspiciatis ipsa,
							odit. Nesciunt dolor minima esse vero ut ea, repudiandae suscipit!</p>
						<h2 class="mb-3 mt-5">#2. Creative WordPress Themes</h2>
						<p>Temporibus ad error suscipit exercitationem hic molestiae totam obcaecati rerum, eius aut, in.
							Exercitationem atque quidem tempora maiores ex architecto voluptatum aut officia doloremque. Error dolore
							voluptas, omnis molestias odio dignissimos culpa ex earum nisi consequatur quos odit quasi repellat qui
							officiis reiciendis incidunt hic non? Debitis commodi aut, adipisci.</p>
						<p>
							<img src="images/image_4.jpg" alt="" class="img-fluid">
						</p>
						<p>Quisquam esse aliquam fuga distinctio, quidem delectus veritatis reiciendis. Nihil explicabo quod, est
							eos ipsum. Unde aut non tenetur tempore, nisi culpa voluptate maiores officiis quis vel ab consectetur
							suscipit veritatis nulla quos quia aspernatur perferendis, libero sint. Error, velit, porro. Deserunt
							minus, quibusdam iste enim veniam, modi rem maiores.</p>
						<p>Odit voluptatibus, eveniet vel nihil cum ullam dolores laborum, quo velit commodi rerum eum quidem
							pariatur! Quia fuga iste tenetur, ipsa vel nisi in dolorum consequatur, veritatis porro explicabo soluta
							commodi libero voluptatem similique id quidem? Blanditiis voluptates aperiam non magni. Reprehenderit
							nobis odit inventore, quia laboriosam harum excepturi ea.</p>
						<p>Adipisci vero culpa, eius nobis soluta. Dolore, maxime ullam ipsam quidem, dolor distinctio similique
							asperiores voluptas enim, exercitationem ratione aut adipisci modi quod quibusdam iusto, voluptates beatae
							iure nemo itaque laborum. Consequuntur et pariatur totam fuga eligendi vero dolorum provident.
							Voluptatibus, veritatis. Beatae numquam nam ab voluptatibus culpa, tenetur recusandae!</p>
						<p>Voluptas dolores dignissimos dolorum temporibus, autem aliquam ducimus at officia adipisci quasi nemo a
							perspiciatis provident magni laboriosam repudiandae iure iusto commodi debitis est blanditiis alias
							laborum sint dolore. Dolores, iure, reprehenderit. Error provident, pariatur cupiditate soluta doloremque
							aut ratione. Harum voluptates mollitia illo minus praesentium, rerum ipsa debitis, inventore?</p>
					</div> <!-- .col-md-8 -->
					<div class="col-lg-4 sidebar ">
						<div class="sidebar-box ">
							<h3 class="heading-sidebar">相关文章</h3>
							<div class="block-21 mb-4 d-flex">
								<!-- <a class="blog-img mr-4" style="background-image: url(images/image_1.jpg);"></a> -->
								<img src="@/assets/images/image_1.jpg" alt="" class="blog-img mr-4">
								<div class="text">
									<h3 class="heading"><a href="#">Even the all-powerful Pointing has no control about the blind
											texts</a></h3>
									<div class="meta">
										<div><a href="#"><span class="icon-calendar"></span> Aug. 12, 2020</a></div>
										<div><a href="#"><span class="icon-person"></span> Admin</a></div>
										<div><a href="#"><span class="icon-chat"></span> 19</a></div>
									</div>
								</div>
							</div>
							<div class="block-21 mb-4 d-flex">
								<!-- <a class="blog-img mr-4" style="background-image: url(images/image_2.jpg);"></a> -->
								<img src="@/assets/images/image_2.jpg" alt="" class="blog-img mr-4">
								<div class="text">
									<h3 class="heading"><a href="#">Even the all-powerful Pointing has no control about the blind
											texts</a></h3>
									<div class="meta">
										<div><a href="#"><span class="icon-calendar"></span> Aug. 12, 2020</a></div>
										<div><a href="#"><span class="icon-person"></span> Admin</a></div>
										<div><a href="#"><span class="icon-chat"></span> 19</a></div>
									</div>
								</div>
							</div>
							<div class="block-21 mb-4 d-flex">
								<!-- <a class="blog-img mr-4" style="background-image: url(images/image_3.jpg);"></a> -->
								<img src="@/assets/images/image_3.jpg" alt="" class="blog-img mr-4">
								<div class="text">
									<h3 class="heading"><a href="#">Even the all-powerful Pointing has no control about the blind
											texts</a></h3>
									<div class="meta">
										<div><a href="#"><span class="icon-calendar"></span> Aug. 12, 2020</a></div>
										<div><a href="#"><span class="icon-person"></span> Admin</a></div>
										<div><a href="#"><span class="icon-chat"></span> 19</a></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {}
</script>

<style>
</style>