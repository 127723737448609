<template>
	<div>
		<section class="hero-wrap js-fullheight"  data-stellar-background-ratio="0.5">
			<div class="overlay">
				<img src="@/assets/images/bg_2.jpg" class="hero-wrap-img" importance="high" @load="imgLoad()" style="min-width: 1920px;"/>
			</div>
			<div class="container">
				<div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center" data-scrollax-parent="true">
					<div class="col-lg-9  text-center">
						<div class="mt-5">
							<h1 class="mb-4" >山东孚岳电气有限公司</h1>
							<!-- <p class="mb-4" style="font-size: 28px;">
								领先、安全、自主、可控,做精做实核心技术，主力推进电力工业
							</p> -->
							<div style="font-size: 28px;">领先 安全 自主 可控</div>
							<div style="font-size: 28px;">做精做实核心技术，主力推进数字产业</div>
							<p style="margin-top: 20px;"><a href="#" class="btn btn-primary" @click="jumpIndexFun('2')">查看详情</a> </p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section bg-light">
			<div class="container-index-list">
				<div class="row justify-content-center mb-5 pb-3">
					<div class="col-md-10 heading-section text-center ">
						<span class="subheading">产品服务</span>
						<h2 class="mb-4">我们的产品服务</h2>
					</div>
				</div>
				<div class="row d-flex">
					<div class="col-lg-3 " v-for="(item, index) in productList" :key="index">
						<div class="blog-entry">
							<a class="block-20 index-top-img" >
								<img :src="$urlData + '/prod-api' + item.picUrl" alt="" style="width: 100%;" :loading="imgLoading">
							</a>
							<div class="text d-block">
								<h3 class="heading"><a >{{ item.title }}</a></h3>
								<p class="index-list-span">{{ item.blurb }}</p>
								<p><a @click="jumpFun(item.id)" class="btn btn-secondary py-2 px-3">了解更多</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="ftco-section testimony-section img" style="background-image: url(images/bg_1.jpg);">
			<div class="overlay"></div>
			<div class="container">
				<div class="row justify-content-center mb-5 pb-3">
					<div class="col-md-7 text-center heading-section heading-section-white ">
						<h2 class="mb-4">创新荣誉</h2>
					</div>
				</div>
				<div class="container">
					<div class=" honor_list">
						<div class="clearfix row d-flex" style="color: #fff;" >
							<div class="col-lg-4 ">
								<div class="honorimg">
									<!-- <div>
										<img src="@/assets/images/certificate.png" />
										电力工程施工总承包二级资质
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										建筑业企业资质证书
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										建筑施工企业安全生产许可证
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										承装（修试）电力设施许可证四级
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										山东省安全技术防范工程设计施工维护许可证三级
									</div> -->
									<div>
										<img src="@/assets/images/certificate.png" >
										2023年电力数字化转型技术创新奖
									</div>
									<div>
										<img src="@/assets/images/certificate.png" >
										淄博市优秀科技副总接收单位
									</div>
									<div>
										<img src="@/assets/images/certificate.png" >
										2020年创客中国优胜企业奖
									</div>
								</div>
							</div>
							<div style="" class="col-lg-4 ">
								<div class="honorimg">
									<!-- <div>
										<img src="@/assets/images/certificate.png" />
										ISO9001质量管理体系认证证书
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										ISO14000环境管理体系认证证书
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										ISO45001职业健康安全管理证书
									</div> -->
									<div>
										<img src="@/assets/images/certificate.png" />
										2019年山东省中小微企业创新竞技行动晋级企业获奖证书
									</div>
									
									<div>
										<img src="@/assets/images/certificate.png" />
										2020年瞪羚培育企业证书
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										高新技术企业
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										山东省高层次人才创业大赛优胜人选证书
									</div>
								</div>
							</div>
							<div class="col-lg-4 ">
								<div class="honorimg">
									
									<div>
										<img src="@/assets/images/certificate.png" />
										山东理工大学研究生联合培养基地
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										山东省专精特新企业
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										2023首届城市电网技术创新成果奖
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row  justify-content-center">
				</div>
			</div>
		</section>
		
		<div class="AboutUs-honor" style="padding-bottom: 10rem;">
			<div class="AboutUs-blurb-title">
				<span>部分合作客户</span>
			</div>
			<div class="AboutUs-blurb-title-text">我们一路同行，一起成长；未来、我们一同见证、一起分享、更显珍贵。</div>
			<div >
				<div class="customer_list">
					<div class="clearfix row d-flex">
						<div v-for="(item, index) in cooperationArr" :key="index" class="col-lg-2 cooperation-list">
							<div class="cooperation-div">
								<img :src="'/prod-api' + item.picUrl" alt="" :loading="imgLoading">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			imgLoading: 'lazy',
			activeIndex: '1',
			displayIf: '1',
			staffData: [],
			productList: [],
			basicsData: '',
			cooperationArr: []
		};
	},
	created() {
		// 员工
		this.staffFun()
		// 产品
		this.productFun()
		// 基础信息
		this.basicsFun()
		// 部分合作客户
		this.cooperationFun()
	},
	methods: {
		imgLoad() {
			this.imgLoading = 'eager'
		},
		// 部分合作客户
		cooperationFun() {
			let url = this.$urlData + '/prod-api/system/customer/list?pageNum=1&pageSize=9999'
			axios.get(url)
				.then(response => {
					this.cooperationArr =  response.data.rows;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		},
		// 基础信息
		basicsFun() {
			let url = this.$urlData + '/prod-api/system/basic/list?pageNum=1&pageSize=1'
			axios.get(url)
				.then(response => {
					this.basicsData =  response.data.rows[0];
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		},
		// 产品
		productFun() {
			let url = this.$urlData + '/prod-api/home/product/list?pageNum=1&pageSize=4&isshow=1'
			axios.get(url)
				.then(response => {
					this.productList =  response.data.rows;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		},
		// 员工
		staffFun() {
			let url = this.$urlData + '/prod-api/home/grace/list?pageNum=1&pageSize=9999'
			axios.get(url)
				.then(response => {
					let staffArr = response.data.rows;
					
					this.staffData = this.chunkArray(staffArr, 3);
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		},
		// 分割数组
		chunkArray(arr, chunkSize) {
			let groups = [];
			for (let i = 0; i < arr.length; i += chunkSize) {
				groups.push(arr.slice(i, i + chunkSize));
			}
			return groups;
		},
		jumpFun(data) {
			console.log(data)
			let jsonData = {
				id: data,
				num: '4-1-1'
			}
			this.$emit('jumpFun', jsonData)
		},
		jumpIndexFun() {
			let jsonData = {
				id: '',
				num: '2'
			}
			this.$emit('jumpFun', jsonData)
		}
	}
}
</script>

<style scoped>

</style>
