<template>
	<div>
		<section class="hero-wrap hero-wrap-2 content-img product-img" data-stellar-background-ratio="0.5">
			<div class="overlay"></div>
			<div class="container">
				<div class="row no-gutters slider-text align-items-end justify-content-start">
					<div class="col-md-9  pb-5">
						<h1 class="mb-3 bread">电力智能化</h1>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 color-span">
						<h2 class="mb-3">{{ dataAll.title }}</h2>
						<p>
							<img :src="$urlData + '/prod-api' + dataAll.picUrl" alt="" class="img-fluid">
						</p>
						<div v-html="dataAll.content"></div>
					</div> <!-- .col-md-8 -->
					<div class="col-lg-4 sidebar ">
						<div class="sidebar-box ">
							<h3 class="heading-sidebar">相关文章</h3>
							<div class="block-21 mb-4 d-flex" v-for="(item, index) in listData" :key="index" @click="jumpFun(item.id)" style="cursor: pointer;">
								<!-- <a class="blog-img mr-4" style="background-image: url(images/image_1.jpg);"></a> -->
								<img :src="$urlData + '/prod-api' + item.picUrl" alt="" class="blog-img mr-4">
								<div class="text">
									<h3 class="heading contentNewsList-div"><a >{{ item.blurb }}</a></h3>
									<div class="meta">{{ item.title }}</div>
									<div class="meta">{{ item.createTime }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="ftco-section" >
			<div class="container-fluid px-md-4 container" >
				<div class="row justify-content-center mb-5 pb-2">
					<div class="col-md-8 text-center heading-section">
						<span class="subheading">客户案例</span>
						<h2 class="mb-4" style="font-size: 26px;">专业从事电力智能化、电力物联网平台研发企业之一</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4" v-for="(item, index) in dataList" :key="index">
						<div class="services-wrap" @click="jumpCaseFun(item.id)">
							<!-- <div class="img" style="background-image: url(images/services-1.jpg);"></div> -->
							<img :src="$urlData + '/prod-api' + item.picUrl" alt="" style="height: 200px; width: 100%;">
							<div class="text">
								<h2>{{ item.title }}</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import axios from 'axios'
	
	export default {
		data() {
			return {
				idData: '',
				dataAll: '',
				listData: [],
				dataList: []
			};
		},
		created() {},
		methods: {
			init() {
				// 详情
				this.detailsFun()
				// 相关文章
				this.relevantFun()
				// 客户案例
				this.caseFun()
			},
			// 客户案例
			caseFun() {
				let url = this.$urlData + '/prod-api/home/case/list?pageNum=1&pageSize=3'
				
				axios.get(url)
					.then(response => {
						this.dataList = response.data.rows;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 相关文章
			relevantFun() {
				let url = this.$urlData + '/prod-api/home/product/list?pageNum=1&pageSize=4&isshow=1'
				axios.get(url)
					.then(response => {
						this.listData =  response.data.rows;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 详情
			detailsFun() {
				let url = this.$urlData + '/prod-api/home/product/' + this.idData
				axios.get(url)
					.then(response => {
						this.dataAll = response.data.data;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 客户案例
			jumpCaseFun(data) {
				let jsonData = {
					id: data,
					num: '9994'
				}
				this.$emit('contentJump', jsonData)
			},
			// 相关文章
			jumpFun(data) {
				let jsonData = {
					id: data,
					num: '4-1-2'
				}
				this.$emit('jumpFun', jsonData)
			},
		}
	}
</script>

<style>
  .color-span{
    color: #000;
  }
</style>