<template>
	<div class="scrollContainer" ref="scrollContainer" id="topID">
		<div class="py-3" v-if="phoneIf == '0'">
			<div class="container">
				<div class="row d-flex align-items-start align-items-center px-3 px-md-0">
					<div class="col-md-4 d-flex mb-2 mb-md-0" style="height: 100%;">
						<a class="navbar-brand d-flex align-items-center">
							<img :src="'/prod-api' + basicsData.picUrl" alt="" class="logoImg">
						</a>
					</div>
					<div class="col-md-4 d-flex topper mb-md-0 mb-2 align-items-center">
						<div class="icon d-flex justify-content-center align-items-center">
							<span class="fa fa-map">
                <img src="@/assets/images/phone.png" alt="">
              </span>
						</div>
						<div class="pr-md-4 pl-md-3 pl-3 text">
							<p class="con"><span style="color: #2EA0D2;">电话</span> <span>{{ basicsData.phone }}</span></p>
							<p class="con">邮箱 {{ basicsData.email }}</p>
						</div>
					</div>
					<div class="col-md-4 d-flex topper mb-md-0 align-items-center">
						<div class="icon d-flex justify-content-center align-items-center">
              <span class="fa fa-paper-plane">
                <img src="@/assets/images/headquarters.png" alt="">
              </span>
						</div>
						<div class="text pl-3 pl-md-3">
							<p class="hr"><span>总部地址</span></p>
							<p class="con">{{ basicsData.address }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="phoneIf == '1'" class="phone-top">
			<img :src="'/prod-api' + basicsData.picUrl" alt="" class="logoImg">
			<div class="phone-top-text">
				<div class="phone-top-title">联系电话</div>
				<div class="phone-top-num">{{ basicsData.phone }}</div>
			</div>
		</div>
		<nav class="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
			<div class="container d-flex align-items-center">
				<!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
					<span class="fa fa-bars"></span> 菜单
				</button> -->
				<!-- <div class="collapse navbar-collapse" id="ftco-nav" style="text-align: center;"> -->
				<div style="text-align: center; width: 100vw;">
					<el-menu
						:default-active="activeIndex"
						class="title-top"
						mode="horizontal"
						background-color="#767676"
						text-color="#fff"
						active-text-color="#2EA0D2"
						@select="handleSelect"
					>
						<el-menu-item index="1"><span class="title-top-span">首页</span></el-menu-item>
						<!-- <el-sub-menu index="2">
							<template #title><span class="title-top-span">关于我们</span></template>
							<el-menu-item index="2-1"><span class="title-top-span">企业简介</span></el-menu-item>
							<el-menu-item index="2-2"><span class="title-top-span">资质荣誉</span></el-menu-item>
							<el-menu-item index="2-3"><span class="title-top-span">企业文化</span></el-menu-item>
							<el-menu-item index="2-4"><span class="title-top-span">展望未来</span></el-menu-item>
						</el-sub-menu> -->
						<el-sub-menu index="3">
							<template #title><span class="title-top-span">新闻</span></template>
							<el-menu-item index="3-1"><span class="title-top-span">行业资讯</span></el-menu-item>
							<el-menu-item index="3-2"><span class="title-top-span">企业新闻</span></el-menu-item>
						</el-sub-menu>
						<el-sub-menu index="4" >
							<template #title><span class="title-top-span">产品服务</span></template>
							<el-menu-item index="4-1"><span class="title-top-span">数字安全</span></el-menu-item>
							<el-menu-item index="4-2"><span class="title-top-span">数字运维</span></el-menu-item>
							<el-menu-item index="4-3"><span class="title-top-span">智能作业</span></el-menu-item>
							<el-menu-item index="4-4"><span class="title-top-span">技术服务</span></el-menu-item>
						</el-sub-menu>
						<!-- <el-sub-menu index="5">
							<template #title><span class="title-top-span">客户服务</span></template>
							<el-menu-item index="5-1"><span class="title-top-span">服务理念</span></el-menu-item>
							<el-menu-item index="5-2"><span class="title-top-span">优秀案例</span></el-menu-item>
						</el-sub-menu> -->
						<el-menu-item index="5"><span class="title-top-span" >客户服务</span></el-menu-item>
						<el-menu-item index="2"><span class="title-top-span" >关于我们</span></el-menu-item>
						<el-menu-item index="6"><span class="title-top-span" >加入我们</span></el-menu-item>
						<!-- <el-sub-menu index="6">
							<template #title><span class="title-top-span">加入我们</span></template>
							<el-menu-item index="6-1"><span class="title-top-span">员工风采</span></el-menu-item>
							<el-menu-item index="6-2"><span class="title-top-span">文体活动</span></el-menu-item>
							<el-menu-item index="6-3"><span class="title-top-span">工作环境</span></el-menu-item>
							<el-menu-item index="6-4"><span class="title-top-span">招聘</span></el-menu-item>
						</el-sub-menu> -->
					</el-menu>
				</div>
			</div>
		</nav>
		<!-- END nav -->
		
		<indexView v-if="displayIf == '1'" @jumpFun="jumpFun"></indexView>
		<contentView v-if="displayIf == '4-1-1' || displayIf == '4-1-2'" ref="contentView" @jumpFun="jumpFun" @contentJump="contentJump"></contentView>
		<!-- 关于我们 -->
		<AboutUs v-if="displayIf == '2'"></AboutUs>
		<listView v-if="displayIf == '3-1' || displayIf == '3-2'" ref="listView" @contentJump="contentJump"></listView>
		<listProduct v-if="displayIf == '4-1' || displayIf == '4-2' || displayIf == '4-3' || displayIf == '4-4'" ref="listProduct" @contentJump="contentJump"></listProduct>
		<caseView v-if="displayIf == '5'" @contentJump="contentJump"></caseView>
		<joinUs v-if="displayIf == '6'"></joinUs>
		<contentList v-if="displayIf == '999'"></contentList>
		<contentNewsList v-if="displayIf == '9991' || displayIf == '9992' || displayIf == '9993'" ref="contentNewsList" @contentJump="contentJump"></contentNewsList>
		
		<footer class="ftco-footer">
			<div class="container mb-5 pb-4">
				<div class="row">
					<div class="col-lg col-md-6">
						<div class="ftco-footer-widget">
							<h2 class="ftco-heading-2">关于我们</h2>
							<ul class="list-unstyled">
								<li><a @click="jumpTopFun('2')"><span class="fa fa-chevron-right mr-2"></span>企业简介</a></li>
								<li><a @click="jumpTopFun('2')"><span class="fa fa-chevron-right mr-2"></span>资质荣誉</a></li>
								<li><a @click="jumpTopFun('2')"><span class="fa fa-chevron-right mr-2"></span>企业文化</a></li>
								<li><a @click="jumpTopFun('2')"><span class="fa fa-chevron-right mr-2"></span>展望未来</a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg col-md-6">
						<div class="ftco-footer-widget">
							<h2 class="ftco-heading-2">新闻</h2>
							<ul class="list-unstyled">
								<li><a @click="jumpTopFun('3-1')"><span class="fa fa-chevron-right mr-2"></span>行业资讯</a></li>
								<li><a @click="jumpTopFun('3-2')"><span class="fa fa-chevron-right mr-2"></span>企业新闻</a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg col-md-6">
						<div class="ftco-footer-widget">
							<h2 class="ftco-heading-2">产品服务</h2>
							<ul class="list-unstyled">
								<li><a @click="jumpTopFun('4-1')"><span class="fa fa-chevron-right mr-2"></span>数字安全</a></li>
								<li><a @click="jumpTopFun('4-2')"><span class="fa fa-chevron-right mr-2"></span>数字运维</a></li>
								<li><a @click="jumpTopFun('4-3')"><span class="fa fa-chevron-right mr-2"></span>智能作业</a></li>
								<li><a @click="jumpTopFun('4-4')"><span class="fa fa-chevron-right mr-2"></span>技术服务</a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg col-md-6">
						<div class="ftco-footer-widget">
							<h2 class="ftco-heading-2">客户服务</h2>
							<ul class="list-unstyled">
								<li><a @click="jumpTopFun('5')"><span class="fa fa-chevron-right mr-2"></span>服务理念</a></li>
								<li><a @click="jumpTopFun('5')"><span class="fa fa-chevron-right mr-2"></span>优秀案例</a></li>
							</ul>
						</div>
					</div>
					
					<div class="col-lg col-md-6">
						<div class="ftco-footer-widget">
							<h2 class="ftco-heading-2">关于我们</h2>
							<div class="block-23 mb-3">
								<ul>
									<li><span class="fa fa-map-marker mr-3"></span><span class="text">{{ basicsData.address }}</span></li>
									<li><a ><span class="fa fa-phone mr-3"></span><span class="text">{{ basicsData.phone }}</span></a></li>
									<li><a ><span class="fa fa-paper-plane mr-3"></span><span class="text">{{ basicsData.email }}</span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid bg-primary py-5">
				<div class="row">
					<div class="col-md-12 text-center">
						<p class="mb-0">山东孚岳电气有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备19017674号-1</a></p>
						</div>
					</div>
				</div>
			</footer>
			
			<el-backtop ></el-backtop>
			<!-- loader -->
			<!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div> -->
	</div>
</template>

<script>
import indexView from "./views/index"
import contentView from "./views/content"
import AboutUs from "./views/AboutUs"
import listView from "./views/list"
import listProduct from "./views/listProduct"
import caseView from "./views/case"
import joinUs from "./views/joinUs"
import contentList from "./views/contentList"
import contentNewsList from "./views/contentNewsList"
import axios from 'axios'

export default {
	name: 'App',
	components: {
		indexView,
		contentView,
		AboutUs,
		listView,
		listProduct,
		caseView,
		joinUs,
		contentList,
		contentNewsList
	},
	data() {
		return {
			phoneIf: '0',
			activeIndex: '1',
			displayIf: '1',
			basicsData: '',
		};
	},
	created() {
		// 基础信息
		this.basicsFun()
	},
	mounted() {
		if( this.isMobile() ){
			console.log("手机端")
			this.phoneIf = '1'
		}else{
			console.log("PC端")
			this.phoneIf = '0'
		}
	},
	methods: {
		//该方法用于判断是否进入手机端
		isMobile(){
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			return flag
		},
		// 基础信息
		basicsFun() {
			let url = this.$urlData + '/prod-api/system/basic/list?pageNum=1&pageSize=1'
			axios.get(url)
				.then(response => {
					this.basicsData =  response.data.rows[0];
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		},
		handleSelect(key) {
			this.displayIf = key
			
			let _this = this
			// 新闻
			if (key == '3-1' || key == '3-2') {
				this.$nextTick(() => {
					_this.$refs.listView.typeData = key
					_this.$refs.listView.init()
				})
			}
			// 产品服务
			if (key == '4-1' || key == '4-2' || key == '4-3' || key == '4-4') {
				this.$nextTick(() => {
					_this.$refs.listProduct.typeData = key
					_this.$refs.listProduct.init()
				})
			}
		},
		// 跳转
		contentJump(data) {
			// 产品跳转案例
			let numData = ''
			if (data.num == '9994') {
				numData = '9994'
				data.num = '9993'
			}
			
			this.displayIf = data.num
			
			let _this = this
			this.$nextTick(() => {
				if (_this.displayIf == '9991' || _this.displayIf == '9992' || _this.displayIf == '9993') {
					_this.$refs.contentNewsList.idData = data.id
					_this.$refs.contentNewsList.typeData = data.num
					_this.$refs.contentNewsList.init()
				}
			})
			
			// 产品跳转案例
			if (numData == '9994') {
				const container = this.$refs.scrollContainer;
				container.scrollTo({ top: 0 });
				var anchorElement = document.getElementById('topID');
				if(anchorElement) {
					anchorElement.scrollIntoView();
				}
			}
		},
		// 底下跳转页面置顶
		jumpTopFun(data) {
			const container = this.$refs.scrollContainer;
			container.scrollTo({ top: 0 });
			var anchorElement = document.getElementById('topID');
			if(anchorElement) {
				anchorElement.scrollIntoView();
			}
			
			this.displayIf = data
			this.activeIndex = data
			
			let _this = this
			// 新闻
			if (data == '3-1' || data == '3-2') {
				this.$nextTick(() => {
					_this.$refs.listView.typeData = data
					_this.$refs.listView.init()
				})
			}
			// 产品服务
			if (data == '4-1' || data == '4-2' || data == '4-3' || data == '4-4') {
				this.$nextTick(() => {
					_this.$refs.listProduct.typeData = data
					_this.$refs.listProduct.init()
				})
			}
		},
		jumpFun(data) {
			this.displayIf = data.num
			this.activeIndex = data.num
      
			if (data.num == '4-1-1') {
				const container = this.$refs.scrollContainer;
				container.scrollTo({ top: 0 });
				var anchorElement = document.getElementById('topID');
				if(anchorElement) {
					anchorElement.scrollIntoView();
				}
			}
			
			let _this = this
			this.$nextTick(() => {
				if (data.num == '4-1-1' || data.num == '4-1-2') {
					_this.$refs.contentView.idData = data.id
					_this.$refs.contentView.init()
				}
			})
		}
	}
}
</script>

<style scope>
	.scrollContainer{
		overflow: auto;
		/* height: 100vh; */
	}
	
	#ftco-navbar .is-active {
		background-color: #2EA0D2 !important;
		border-bottom: 0px !important;
		color: #fff !important;
	}
	
	.el-popper .is-active {
		background-color: #2EA0D2 !important;
		border-bottom: 0px !important;
		color: #fff !important;
	}
	
	.el-menu--horizontal.el-menu{
		border-bottom: 0px !important;
	}
	
	.el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title{
		border-bottom: 0px !important;
	}
	
	.el-menu--horizontal>.el-sub-menu .el-sub-menu__title:hover{
		background-color: #2EA0D2 !important;
	}
	
	.title-top .el-menu-item:hover {
		background-color: #2EA0D2 !important;
		color: #fff;
	}
</style>
