<template>
	<div>
		<div class="AboutUs-blurb">
			<div class="AboutUs-blurb-title">
				<span>企业简介</span>
			</div>
			<div class="AboutUs-blurb-title-text">电力行业整体解决方案供应商</div>
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="AboutUs-blurb-img">
							<div style="flex: 5;">
								<img src="@/assets/images/AboutUs1.jpg" alt="" style="width: 100%; height: 295px;">
							</div>
							<div style="flex: 6.3; margin-left: 10px;">
								<img src="@/assets/images/AboutUs2.jpg" alt="" class="AboutUs-blurb-imgTop">
								<img src="@/assets/images/AboutUs3.jpg" alt="" class="AboutUs-blurb-imgLeft">
								<img src="@/assets/images/AboutUs4.jpg" alt="" class="AboutUs-blurb-imgRight">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<el-scrollbar class="AboutUs-blurb-title-p">
							<p>山东孚岳电气有限公司是一家专注于电气行业数字化、智能化的高新技术企业，构建了集研发、生产、实施、服务于一体的综合业务体系。我们秉承“创新变革、合作共赢 ”的理念，致力于为电力、水利、消防、海港等多个行业客户提供数智化技术应用的卓越解决方案，是推动相关行业数智化发展的领先者。</p>
							<p>公司自成立以来，一直保持稳步发展，获评中国创新科技企业、山东省专精特新企业、高新技术企业、淄博市瞪羚培育企业、山东半岛自创区优秀企业等称号，并获批泰山系列人才工程 、淄博高新区高层次人才创业项目 、淄博市英才计划 等多项政府重点扶持项目。公司建有高新技术创新实验室，与同济大学、南京理工大学、瑞典兰德大学等国内外著名高校保持合作交流关系，与山东理工大学联合成立“研究生联合培养基地 ”，并聘请多位高校的专家、学者作为企业的科技副总，携手推动科技研发、加速产学研合作项目落地。</p>
							<p>公司的数智化技术和产品在电力、水利、消防等行业得以广泛推广和应用，拥有数字安全、数字运维、智能作业等相关领域的自主知识产权160余项。公司在电气自动化领域享有较高的知名度和美誉度，是众多客户的首选合作伙伴。公司多次参会诸如青岛上合峰会等重大活动的保电工作，以及省内外多个超（特）高压输变电工程的检修工作，获得客户一致好评。</p>
							<p>展望未来，山东孚岳电气有限公司将继续秉承“ 创新变革、合作共赢”的理念，持续加大研发投入、拓展业务领域、提升服务质量，为广大客户提供最优质的产品及超一流的服务，助力数智化产业高质量发展。我们期待与更多合作伙伴携手共进，共创辉煌未来！</p>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</div>
		
		<div class="AboutUs-Culture">
			<div class="AboutUs-blurb-title">
				<span>企业文化</span>
			</div>
			<div class="AboutUs-blurb-title-text">电力行业整体解决方案供应商</div>
			<div class="AboutUs-Culture-div">
				<div class="container">
					<p class="culture_contit">【经营理念】</p>
					<p class="culture_contex">立足于“大、云、物、移、智”前沿技术，</p>
					<p class="culture_contex">致力于行业数字化、智能化技术服务，</p>
					<p class="culture_contex">为电力、水利、消防、海港等各行业</p>
					<p class="culture_contex">提供优质的整体解决方案。</p>
					<p class="culture_contit">【团队使命】</p>
					<p class="culture_contex">为客户提供体验优越、性能良好、</p>
					<p class="culture_contex">稳定可靠的软硬件产品和</p>
					<p class="culture_contex">全方位的专业化服务，</p>
					<p class="culture_contex">打造数智化产业的优秀企业品牌。</p>
					<p class="culture_contit">【 核心价值观】</p>
					<p class="culture_contex">诚信合作、客户至上</p>
					<p class="culture_contex">科技创新、勇于超越</p>
					<p class="culture_contex">团结协作、尊重信任</p>
					<p class="culture_contex">言出必行、行则必果</p>
					<p class="culture_contit">【营销理念】</p>
					<p class="culture_contex">一流的产品、一流的服务</p>
					<p class="culture_contit">【企业作风】</p>
					<p class="culture_contex">团结、拼搏、务实、高效</p>
					<p class="culture_contit">【企业使命】</p>
					<p class="culture_contex">做精做实核心技术</p>
					<p class="culture_contex">主力推进电力工业</p>
					<div style="clear: both;"></div>
				</div>
			</div>
		</div>
		
		<section class="ftco-section testimony-section img" style="background-image: url(images/bg_1.jpg);">
			<div class="overlay"></div>
			<div class="container">
				<div class="row justify-content-center mb-5 pb-3">
					<div class="col-md-7 text-center heading-section heading-section-white ">
						<h2 class="mb-4">创新荣誉</h2>
					</div>
				</div>
				<div class="container">
					<div class=" honor_list">
						<div class="clearfix row d-flex" style="color: #fff;" >
							<div class="col-lg-4 ">
								<div class="honorimg">
									<div>
										<img src="@/assets/images/certificate.png" >
										2023年电力数字化转型技术创新奖
									</div>
									<div>
										<img src="@/assets/images/certificate.png" >
										淄博市优秀科技副总接收单位
									</div>
									<div>
										<img src="@/assets/images/certificate.png" >
										2020年创客中国优胜企业奖
									</div>
								</div>
							</div>
							<div style="" class="col-lg-4 ">
								<div class="honorimg">
									<div>
										<img src="@/assets/images/certificate.png" />
										2019年山东省中小微企业创新竞技行动晋级企业获奖证书
									</div>
									
									<div>
										<img src="@/assets/images/certificate.png" />
										2020年瞪羚培育企业证书
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										高新技术企业
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										山东省高层次人才创业大赛优胜人选证书
									</div>
								</div>
							</div>
							<div class="col-lg-4 ">
								<div class="honorimg">
									<div>
										<img src="@/assets/images/certificate.png" />
										山东理工大学研究生联合培养基地
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										山东省专精特新企业
									</div>
									<div>
										<img src="@/assets/images/certificate.png" />
										2023首届城市电网技术创新成果奖
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row  justify-content-center">
				</div>
			</div>
		</section>
		
		<div class="AboutUs-expectation expectation">
			<div class="AboutUs-blurb-title">
				<span>展望未来</span>
			</div>
			<div class="container" style="text-align: center; padding-top: 50px; padding-bottom: 50px;">
				<p>在全球能源转型的大背景下，电力企业正站在一个充满机遇与挑战的十字路口。未来，我们可以预见到以下令人振奋的发展趋势和前景。</p>
				<p>能源多元化将成为电力企业发展的主旋律。随着可再生能源技术的不断进步，太阳能、风能、水能等清洁能源在电力供应中的比重将持续上升。电力企业将加大对这些领域的投资和研发，不断提高能源转化效率，降低成本，使清洁能源成为更加可靠和经济的能源选择。</p>
				<p>智能化电网将成为电力系统的核心架构。通过先进的传感器、通信技术和数据分析，电网将能够实现更高效的能源分配、实时监测和故障预警。这不仅能提高电力供应的稳定性和可靠性，还能更好地整合分布式能源资源，实现能源的优化利用。</p>
				<p>储能技术的突破将为电力行业带来革命性的变化。高效、大容量的储能系统将能够解决可再生能源的间歇性问题，使得电力的供应更加平稳和可持续。电力企业将积极探索和应用新型储能技术，如电池储能、超级电容储能等，为能源存储和释放提供更优解决方案。
电力企业的角色也将发生转变，从单纯的能源供应商向综合能源服务提供商拓展。除了提供电力，还将为用户提供能源管理、节能咨询、分布式能源系统集成等多元化的服务，满足用户日益增长的个性化能源需求。</p>
				<p>在国际合作方面，电力企业将更加积极地参与全球能源治理，加强与其他国家和地区的能源合作与交流。共同应对能源安全、气候变化等全球性挑战，推动建立更加公平、高效、可持续的国际能源秩序。</p>
				<p>未来的电力企业将以科技创新为驱动，以绿色发展为理念，以服务社会为己任。在能源转型的浪潮中勇立潮头，为人类创造一个更加清洁、智能、美好的能源未来。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style>
</style>