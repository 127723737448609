<template>
	<div>
		<section class="hero-wrap hero-wrap-2 " data-stellar-background-ratio="0.5">
			<div class="overlay">
				<img src="@/assets/images/productImg.jpg" class="hero-wrap-img" importance="high" @load="imgLoad()" />
			</div>
			<div class="container">
				<div class="row no-gutters slider-text align-items-end justify-content-start">
					<div class="col-md-9  pb-5">
						<h1 class="mb-3 bread">产品服务</h1>
					</div>
				</div>
			</div>
		</section>
		<section class="ftco-section">
			<div class="container">
				<div class="row justify-content-center mb-5 pb-2">
					<div class="col-md-8 text-center heading-section ">
						<span class="subheading">产品服务</span>
						<h2 class="mb-4">{{ title }}</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4" v-for="(item, index) in dataList" :key="index">
						<div class="services-wrap " @click="jumpFun(item.id)">
							<img :src="$urlData + '/prod-api' + item.picUrl" alt="" class="img" :loading="imgLoading">
							<div class="text">
								<h2>{{ item.title }}</h2>
								<div class="list-content">
									{{ item.blurb }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col text-center">
						<div class="block-27" style="float: right;">
							<el-pagination background layout="prev, pager, next" :total="total" :current-page="queryParams.pageNum"
								:page-size="queryParams.pageSize" @update:current-page='getList'>
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		data() {
			return {
				imgLoading: 'lazy',
				title: '',
				typeData: '',
				// 总条数
				total: 0,
				dataList: [],
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 6,
				},
			};
		},
		created() {},
		methods: {
			imgLoad() {
				this.imgLoading = 'eager'
			},
			init() {
				this.queryParams = {
					pageNum: 1,
					pageSize: 6,
				}
				let category = ''
				// 数字安全
				if (this.typeData == '4-1') {
					category = '0'
					this.title = '数字安全'
					// 数字运维方面
				} else if (this.typeData == '4-2') {
					category = '1'
					this.title = '数字运维'
					// 智能作业方面
				} else if (this.typeData == '4-3') {
					category = '2'
					this.title = '智能作业'
					// 技术服务方面
				} else if (this.typeData == '4-4') {
					category = '3'
					this.title = '技术服务'
				}
				let url = this.$urlData + '/prod-api/home/product/list?isshow=2&pageNum=' + this.queryParams.pageNum +
					'&pageSize=' + this
					.queryParams.pageSize + '&category=' + category
				axios.get(url)
					.then(response => {
						this.dataList = response.data.rows;
						this.total = response.data.total;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			getList(data) {
				// 当前页数
				this.queryParams.pageNum = data
				let category = ''
				// 数字安全
				if (this.typeData == '4-1') {
					category = '0'
					// 数字运维
				} else if (this.typeData == '4-2') {
					category = '1'
					// 智能作业
				} else if (this.typeData == '4-3') {
					category = '2'
					// 技术服务
				} else if (this.typeData == '4-4') {
					category = '3'
				}
				let url = this.$urlData + '/prod-api/home/product/list?isshow=2&pageNum=' + this.queryParams.pageNum +
					'&pageSize=' + this
					.queryParams.pageSize + '&category=' + category
				axios.get(url)
					.then(response => {
						this.dataList = response.data.rows;
						this.total = response.data.total;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			jumpFun(data) {
				let jsonData = {
					id: data,
					num: '9992'
				}
				this.$emit('contentJump', jsonData)
			}
		}
	};
</script>

<style>
</style>