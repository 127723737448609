<template>
	<div>
		<section class="hero-wrap hero-wrap-2 " data-stellar-background-ratio="0.5">
			<div class="overlay">
				<img src="@/assets/images/caseImg.jpg" class="hero-wrap-img" importance="high" @load="imgLoad()" />
			</div>
			<div class="container">
				<div class="row no-gutters slider-text align-items-end justify-content-start">
					<div class="col-md-9  pb-5">
						<h1 class="mb-3 bread">客户服务</h1>
					</div>
				</div>
			</div>
		</section>
		<section class="ftco-section" style="padding-bottom: 0px;">
			<div class="container">
				<div class="row justify-content-center mb-5 pb-2">
					<div class="col-md-12 text-center heading-section ">
						<!-- <span class="subheading">优秀案例</span> -->
						<h2 class="mb-4">服务理念</h2>
						<div class="container" style="text-align: center; padding-top: 30px; padding-bottom: 30px; font-size: 20px;">
							立足于“大、云、物、移、智”前沿技术，致力于行业数字化、智能化技术服务，为电力、水利、消防、海港等各行业提供优质的整体解决方案。
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="ftco-section" style="padding-bottom: 3rem;">
			<div class="container">
				<div class="row justify-content-center mb-5 pb-2">
					<div class="col-md-8 text-center heading-section ">
						<span class="subheading">优秀案例</span>
						<h2 class="mb-4">案例展示</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4" v-for="(item, index) in dataList" :key="index">
						<div class="services-wrap " @click="jumpFun(item.id)">
							<img :src="$urlData + '/prod-api' + item.picUrl" alt="" class="img" :loading="imgLoading">
							<div class="text">
								<h2>{{ item.title }}</h2>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col text-center">
						<div class="block-27" style="float: right;">
							<el-pagination background layout="prev, pager, next" :total="total" :current-page="queryParams.pageNum"
								:page-size="queryParams.pageSize" @update:current-page='getList'>
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<div class="AboutUs-honor" style="padding-bottom: 10rem;">
			<div class="AboutUs-blurb-title">
				<span>部分合作客户</span>
			</div>
			<div class="AboutUs-blurb-title-text">我们一路同行，一起成长；未来、我们一同见证、一起分享、更显珍贵。</div>
			<div >
				<div class="customer_list">
					<div class="clearfix row d-flex">
						<div v-for="(item, index) in cooperationArr" :key="index" class="col-lg-2 cooperation-list">
							<div class="cooperation-div">
								<img :src="'/prod-api' + item.picUrl" alt="" :loading="imgLoading">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	
	export default {
		data() {
			return {
				imgLoading: 'lazy',
				cooperationArr: [],
				// 总条数
				total: 0,
				dataList: [],
				queryParams: {
					pageNum: 1,
					pageSize: 3,
				},
			}
		},
		created() {
			this.caseListFun()
			// 部分合作客户
			this.cooperationFun()
		},
		methods: {
			imgLoad() {
				this.imgLoading = 'eager'
			},
			// 部分合作客户
			cooperationFun() {
				let url = this.$urlData + '/prod-api/system/customer/list?pageNum=1&pageSize=9999'
				axios.get(url)
					.then(response => {
						this.cooperationArr =  response.data.rows;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			caseListFun() {
				let url = this.$urlData + '/prod-api/home/case/list?pageNum=' + this.queryParams.pageNum + '&pageSize=' + this
					.queryParams.pageSize
				axios.get(url)
					.then(response => {
						this.dataList = response.data.rows;
						this.total = response.data.total;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			getList(data) {
				// 当前页数
				this.queryParams.pageNum = data
				let url = this.$urlData + '/prod-api/home/case/list?pageNum=' + this.queryParams.pageNum + '&pageSize=' + this
					.queryParams.pageSize
				axios.get(url)
					.then(response => {
						this.dataList = response.data.rows;
						this.total = response.data.total;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			jumpFun(data) {
				let jsonData = {
					id: data,
					num: '9993'
				}
				this.$emit('contentJump', jsonData)
			}
		}
	}
</script>

<style>
</style>