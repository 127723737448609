<template>
	<div>
		<section class="hero-wrap hero-wrap-2 " data-stellar-background-ratio="0.5">
			<div class="overlay">
				<img src="@/assets/images/joinUsImg.jpg" class="hero-wrap-img" importance="high" />
			</div>
			<div class="container">
				<div class="row no-gutters slider-text align-items-end justify-content-start">
					<div class="col-md-9  pb-5">
						<h1 class="mb-3 bread">加入我们</h1>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section ftco-no-pt ftco-no-pb ftco-services-2 bg-primary">
			<div class="container">
				<div class="row d-flex">
					<div class="col-md-7 py-5">
						<div class="py-lg-5">
							<div class="row justify-content-center pb-5">
								<div class="col-md-12 heading-section ">
									<h2 class="mb-3">为什么选择加入我们</h2>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 col-lg-6 d-flex align-self-stretch ">
									<div class="media block-6 services d-flex">
										<div class="icon justify-content-center align-items-center d-flex">
											<span class="flaticon-customer-service">
												<img src="@/assets/images/team.png" alt="" style="width: 60px;">
											</span>
										</div>
										<div class="media-body pl-4">
											<!-- <h3 class="heading mb-3">24/7 Customer Service</h3> -->
											<p>有活力的团队：这是一个年轻的，充满活力和创造力的团队</p>
										</div>
									</div>      
								</div>
								<div class="col-md-12 col-lg-6 d-flex align-self-stretch ">
									<div class="media block-6 services d-flex">
										<div class="icon justify-content-center align-items-center d-flex">
											<span class="flaticon-road-roller">
												<img src="@/assets/images/promotion.png" alt="" style="width: 60px;">
											</span>
										</div>
										<div class="media-body pl-4">
											<!-- <h3 class="heading mb-3">Prompt Delivery</h3> -->
											<p>公平的晋升机制：是金子就会发光，你的才华不会被埋没</p>
										</div>
									</div>      
								</div>
								<div class="col-md-12 col-lg-6 d-flex align-self-stretch ">
									<div class="media block-6 services d-flex">
										<div class="icon justify-content-center align-items-center d-flex">
											<span class="flaticon-road-roller">
												<img src="@/assets/images/activity.png" alt="" style="width: 60px;">
											</span>
										</div>
										<div class="media-body pl-4">
											<!-- <h3 class="heading mb-3">Reliable Equipment</h3> -->
											<p>丰富的文体活动：公司不定期的团建，加强团队凝聚力，放松心情，丰富员工的精神生活</p>
										</div>
									</div>      
								</div>
								<div class="col-md-12 col-lg-6 d-flex align-self-stretch ">
									<div class="media block-6 services d-flex">
										<div class="icon justify-content-center align-items-center d-flex">
											<span class="flaticon-road-roller">
												<img src="@/assets/images/care.png" alt="" style="width: 60px;">
											</span>
										</div>
										<div class="media-body pl-4">
											<!-- <h3 class="heading mb-3">New Heavy Equipment</h3> -->
											<p>人文关怀:五险一金，节日福利，生日福利</p>
										</div>
									</div>      
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-5 d-flex">
						<div class="appointment-wrap p-4 p-lg-5 d-flex align-items-center">
							<div class="overlay"></div>
							<div class="appointment-form ">
								<h3>加入我们</h3>
								<div class="">
									<div class="form-group">
										<input type="text" v-model="form.name" class="form-control" placeholder="姓名">
									</div>
									<div class="form-group">
										<input type="text" v-model="form.phone" class="form-control" placeholder="手机号">
									</div>
									<div class="form-group">
										<input type="text" v-model="form.email" class="form-control" placeholder="邮箱">
									</div>
								</div>
								<div class="">
									<div class="form-group">
										<div class="form-field">
											<div class="select-wrap">
												<div class="icon"><span class="fa fa-chevron-down"></span></div>
												<input type="text" v-model="form.qualification" class="form-control" placeholder="学历">
												<!-- <select class="form-control" v-model="form.qualification" placeholder="学历">
													<option value="">中专</option>
													<option value="">大专</option>
													<option value="">本科</option>
													<option value="">硕士</option>
													<option value="">博士</option>
												</select> -->
											</div>
										</div>
									</div>
								</div>
								<div class="">
									<div class="form-group">
										<textarea v-model="form.blurb" cols="30" rows="4" class="form-control" placeholder="个人简介"></textarea>
									</div>
									<div class="form-group">
										<input type="submit" value="加入我们" class="btn btn-primary py-3 px-4" @click="saveFun()">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<!-- <section class="ftco-section testimony-section img" style="padding-bottom: 0px !important;">
			<div class="overlay"></div>
			<div class="">
				<div class="row justify-content-center mb-5 pb-3">
					<div class="col-md-7 text-center heading-section heading-section-white ">
						<span class="subheading" style="color: #2EA0D2 !important;">工作环境</span>
						<h2 class="mb-4">我们的工作环境</h2>
					</div>
				</div>
				<div class="row no-gutters">
					<div class="col-md-3 joinUs-img" v-for="(item, index) in workData" :key="index">
						<img :src="$urlData + '/prod-api' + item.picUrl" alt="" @click="imgSee(item.picUrl)">
					</div>
				</div>
			</div>
		</section> -->
		
		<el-image-viewer v-if="showViewer" @close="closeViewer" :url-list="clickImg" :z-index="9999" />
	</div>
</template>

<script>
	import { ElImageViewer } from "element-plus"
	import axios from 'axios'
	
	export default {
		components: {
			ElImageViewer,
		},
		data() {
			return {
				showViewer: false,
				clickImg: [],
				staffData: [],
				workData: [],
				form: {
					name: '',
					phone: '',
					email: '',
					qualification: '',
					blurb: '',
				}
			}
		},
		created() {
			// 员工
			this.staffFun()
			// 工作环境
			this.workFun()
		},
		methods: {
			// 工作环境
			workFun() {
				let url = this.$urlData + '/prod-api/home/environment/list?pageNum=1&pageSize=4'
				axios.get(url)
					.then(response => {
						this.workData = response.data.rows
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 加入我们
			saveFun() {
				if (this.form.name == '') {
					this.$message({
						message: '姓名不能为空',
						type: 'warning'
					});
					return
				}
				const phoneReg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
				if (!phoneReg.test(this.form.phone)) {
					this.$message({
						message: '手机号格式不正确',
						type: 'warning'
					});
					return
				}
				const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (!emailRegex.test(this.form.email)) {
					this.$message({
						message: '邮箱格式不正确',
						type: 'warning'
					});
					return
				}
				
				let url = this.$urlData + '/prod-api/home/us'
				axios.post(url, this.form)
					.then(response => {
						if (response.data.code == 200) {
							this.$message({
								message: '发送成功',
								type: 'success'
							});
							this.form = {
								name: '',
								phone: '',
								email: '',
								qualification: '',
								blurb: '',
							}
						}
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 员工
			staffFun() {
				let url = this.$urlData + '/prod-api/home/grace/list?pageNum=1&pageSize=9999'
				axios.get(url)
					.then(response => {
						let staffArr = response.data.rows;
						
						this.staffData = this.chunkArray(staffArr, 3);
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 分割数组
			chunkArray(arr, chunkSize) {
				let groups = [];
				for (let i = 0; i < arr.length; i += chunkSize) {
					groups.push(arr.slice(i, i + chunkSize));
				}
				return groups;
			},
			closeViewer() {
				this.showViewer = false;
			},
			imgSee(data) {
				let imgUrl = this.$urlData + '/prod-api' + data
				
				this.clickImg = [imgUrl];
				this.showViewer = true;
			}
		}
	}
</script>

<style>
</style>