<template>
	<div>
		<section class="hero-wrap hero-wrap-2" data-stellar-background-ratio="0.5">
			<div class="overlay">
				<img v-show="typeData == '9991'" src="@/assets/images/newsImg.jpg" alt="" class="hero-wrap-img" importance="high">
				<img v-show="typeData != '9991'" src="@/assets/images/productImg.jpg" alt="" class="hero-wrap-img" importance="high">
			</div>
			<div class="container">
				<div class="row no-gutters slider-text align-items-end justify-content-start">
					<div class="col-md-9  pb-5">
						<h1 class="mb-3 bread">{{ typeData == '9991' ? '新闻中心' : '产品服务' }}</h1>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 color-span">
						<h2 class="mb-3">{{ dataAll.title }}</h2>
						<img :src="$urlData + '/prod-api' + dataAll.picUrl" alt="" class="img contentNewsList-img">
						<div v-html="dataAll.content"></div>
					</div> <!-- .col-md-8 -->
					<div class="col-lg-4 sidebar ">
						<div class="sidebar-box ">
							<h3 class="heading-sidebar">相关文章</h3>
							<div class="block-21 mb-4 d-flex" v-for="(item, index) in dataList" :key="index" @click="jumpFun(item.id)" style="cursor: pointer;">
								<!-- <a class="blog-img mr-4" style="background-image: url(images/image_1.jpg);"></a> -->
								<img :src="$urlData + '/prod-api' + item.picUrl" alt="" class="blog-img mr-4">
								<div class="text">
									<h3 class="heading contentNewsList-div"><a >{{ item.blurb }}</a></h3>
									<div class="meta">{{ item.title }}</div>
									<div class="meta">{{ item.createTime }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import axios from 'axios'
	
	export default {
		data() {
			return {
				idData: '',
				typeData: '',
				dataAll: '',
				dataList: []
			};
		},
		created() {},
		methods: {
			init() {
				// 详情
				this.detailsFun()
				// 关联内容
				// this.relationFun()
			},
			jumpFun(data) {
				let jsonData = {
					id: data,
					num: this.typeData
				}
				this.$emit('contentJump', jsonData)
			},
			detailsFun() {
				let url = this.$urlData + '/prod-api/home/news/' + this.idData
				// 产品服务
				if (this.typeData == '9992') {
					url = this.$urlData + '/prod-api/home/product/' + this.idData
				} else if (this.typeData == '9993') {
					url = this.$urlData + '/prod-api/home/case/' + this.idData
				}
				
				axios.get(url)
					.then(response => {
						this.dataAll = response.data.data;
						// 关联内容
						this.relationFun()
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			// 关联内容
			relationFun() {
				let url = this.$urlData + '/prod-api/home/news/list?pageNum=1&pageSize=3&category=' + this.dataAll.category
				// 产品服务
				if (this.typeData == '9992') {
					url = this.$urlData + '/prod-api/home/product/list?isshow=2&pageNum=1&pageSize=3&category=' + this.dataAll.category
				} else if (this.typeData == '9993') {
					url = this.$urlData + '/prod-api/home/case/list?pageNum=1&pageSize=3'
				}
				
				axios.get(url)
					.then(response => {
						this.dataList = response.data.rows;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			}
		}
	}
</script>

<style>
	.contentNewsList-img{
		max-width: 100%;
	}
  
  .color-span{
    color: #000;
  }
</style>
